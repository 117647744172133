<template>
  <v-container fluid>
    <v-card elevation="0">
      <v-card-title>기사 리포트</v-card-title>
      <v-row no-gutters class="px-5">
        <v-col cols="12" lg="3" sm="12">
          <v-autocomplete
            v-model="media_id"
            :items="media_items"
            label="언론사"
            item-text="name"
            item-value="id"
            placeholder="언론사를 선택하세요."
            no-data-text="존재하지 않는 언론사입니다."
            hide-details
            @change="getData()"
          >
          </v-autocomplete>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" lg="1" sm="12">
          <v-select
            v-model="sort"
            :items="sort_items"
            label="정렬"
            item-text="text"
            item-value="value"
            @change="getData()"
          >
          </v-select>
        </v-col>
        <v-btn-toggle
          v-model="interval"
          class="mx-auto mt-2 px-5"
          dense
          mandatory
        >
          <v-btn
            v-for="type in intervalType"
            :key="type.value"
            :value="type.value"
            @click="changeType(type.value)"
            class="px-3 py-5"
          >
            {{ type.text }}
          </v-btn>
        </v-btn-toggle>
        <v-col cols="12" lg="1" sm="12">
          <v-menu
            v-model="startMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="100%"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="startDate"
                readonly
                v-bind="attrs"
                v-on="on"
                label="시작 날짜"
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              ref="startDate"
              @input="startMenu = false"
              :max="endDate"
              locale="ko-KR"
              no-title
            ></v-date-picker>
          </v-menu>
        </v-col>
        <p class="text-center mx-auto px-5 mt-5">~</p>
        <v-col cols="12" lg="1" sm="12" class="mr-5">
          <v-menu
            v-model="endMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="100%"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="endDate"
                readonly
                v-bind="attrs"
                v-on="on"
                label="종료 날짜"
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              ref="endDate"
              @input="endMenu = false"
              :min="startDate"
              :max="today"
              locale="ko-KR"
              no-title
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-btn color="primary" @click="getData()" class="mx-auto mt-3"
          >검색</v-btn
        >
      </v-row>
    </v-card>
    <v-progress-circular
      v-if="loading"
      :indeterminate="loading"
      class="progress_circular"
    ></v-progress-circular>
    <v-row class="mt-5 mb-16">
      <v-col>
        <v-simple-table>
          <template>
            <thead class="sticky_header">
              <tr>
                <th class="text-center">순위</th>
                <th class="text-center">언론사</th>
                <th class="text-center">콘텐츠</th>
                <th class="text-center">PV</th>
                <th class="text-center">UV</th>
                <th class="text-center">CVR</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in tableItems" :key="i">
                <td>
                  <v-icon
                    v-if="item.rank < 4"
                    :color="getRankColor(item.rank)"
                    class="mr-1"
                    >mdi-crown-outline</v-icon
                  >{{ item.rank }}위
                </td>
                <td>{{ item.media_name }}</td>
                <td class="text-left">
                  <a
                    :href="item.url"
                    target="_blank"
                    style="text-decoration: none"
                    onMouseOver="this.style.textDecoration='underline'"
                    onMouseOut="this.style.textDecoration='none'"
                  >
                    <div class="d-flex align-center py-2">
                      <v-img
                        :src="item.img"
                        min-width="80px"
                        min-height="80px"
                        max-width="80px"
                        max-height="80px"
                        style="display: inline-block"
                      ></v-img>
                      <span class="ml-3" style="display: inline-block">{{
                        item.title
                      }}</span>
                    </div>
                  </a>
                </td>
                <td>{{ item.pv.toLocaleString() }}</td>
                <td>{{ item.uv.toLocaleString() }}</td>
                <td>{{ item.cvr }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <ScrollTop />
  </v-container>
</template>

<script>
import moment from "moment";
import ScrollTop from "@/components/common/ScrollTop.vue";

export default {
  name: "ArticleReport",
  components: {
    ScrollTop,
  },
  data() {
    return {
      media_id: "",
      media_items: [
        {
          id: "",
          name: "전체",
        },
      ],

      sort: "",
      sort_items: [
        {
          text: "전체",
          value: "",
        },
        {
          text: "기사 뷰",
          value: "view",
        },
        {
          text: "설문 노출",
          value: "impression",
        },
        {
          text: "설문 응답",
          value: "survey",
        },
      ],

      interval: "today",
      intervalType: [
        {
          text: "오늘",
          value: "today",
        },
        {
          text: "최근 7일",
          value: "week",
        },
        {
          text: "최근 30일",
          value: "month",
        },
        {
          text: "최근 3개월",
          value: "3month",
        },
      ],

      today: moment().format("YYYY-MM-DD"),
      startDate: null,
      startMenu: false,
      endDate: null,
      endMenu: false,

      loading: false,
      tableItems: [],
    };
  },
  mounted() {
    this.initDate();
    this.getMedia();
    this.getData();
  },
  methods: {
    initDate() {
      this.startDate = this.today;
      this.endDate = this.today;
    },

    getMedia() {
      this.axios.get("api/v1/manage/list/medias").then((res) => {
        if (res.data.list.data.length > 0) {
          res.data.list.data.map((item) => {
            this.media_items.push({
              id: item.id,
              name: item.name,
            });
          });
        }
      });
    },

    getRankColor(rank) {
      if (rank === 1) {
        return "#FFD700";
      } else if (rank === 2) {
        return "#C0C0C0";
      } else if (rank === 3) {
        return "#CD7F32";
      }
    },

    changeType(type) {
      this.interval = type;
      if (type === "week") {
        this.startDate = moment(this.today)
          .subtract(7, "d")
          .format("YYYY-MM-DD");
        this.endDate = this.today;
      } else if (type === "month") {
        this.startDate = moment(this.today)
          .subtract(1, "M")
          .format("YYYY-MM-DD");
        this.endDate = this.today;
      } else if (type === "3month") {
        this.startDate = moment(this.today)
          .subtract(3, "M")
          .format("YYYY-MM-DD");
        this.endDate = this.today;
      } else if (type === "today") {
        this.initDate();
      }
      this.getData();
    },

    async getData() {
      this.loading = true;
      this.tableItems = [];

      let params = {
        media_id: this.media_id,
        start_at: this.startDate,
        end_at: this.endDate,
        sort: this.sort,
      };
      await this.axios
        .get(`api/v1/stats/article-ranks`, { params })
        .then((res) => {
          if (res.data.stats.length > 0) {
            this.tableItems = res.data.stats;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.v-application th,
.v-application td {
  white-space: nowrap;
}
.progress_circular {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.v-data-table >>> .v-data-table__wrapper {
  overflow: unset;
}
@media all and (max-width: 1023px) {
  .v-data-table >>> .v-data-table__wrapper {
    overflow-x: auto;
    overflow-y: hidden;
  }
}
</style>
